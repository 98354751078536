import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Layout } from "../components";
import Content, { HTMLContent } from "../components/Content";
import { Container, Wrapper } from "../ui/LayoutComponents";
import { PageTitle } from "../ui/Texts";

export const DonMoelleOsseusePostTemplate = ({ title, slug, description, content, contentComponent }) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <SEO article title={title} description={description} url={slug} />
      <Container>
        <PageTitle>{title}</PageTitle>
        <Wrapper>
          <PostContent className="markdown" content={content} />
        </Wrapper>
      </Container>
    </>
  );
};

DonMoelleOsseusePostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string,
  excerpt: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
};

const DonMoelleOsseusePost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <DonMoelleOsseusePostTemplate
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        slug={post.fields.slug}
        excerpt={post.excerpt}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

DonMoelleOsseusePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default DonMoelleOsseusePost;

export const pageQuery = graphql`
  query DonMoelleOsseusePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
      }
    }
  }
`;
